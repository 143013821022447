// src/utils/tracking.js
import TagManager from 'react-gtm-module';
import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

window.dataLayer = window.dataLayer || [];

let gtmInitialized = false;

export const initGTM = () => {
    if (gtmInitialized) return;
    TagManager.initialize({gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID});
    gtmInitialized = true;
};


export const PageTracker = () => {
    const location = useLocation();
    const prevPathRef = useRef(null);

    useEffect(() => {
        // Get full current URL
        const currentUrl = window.location.href;

        // Push to dataLayer with proper referrer handling
        window.dataLayer.push({
            event: 'pageview',
            page_path: location.pathname,
            page_title: document.title,
            page_location: currentUrl,
            page_referrer: prevPathRef.current || document.referrer
        });

        prevPathRef.current = currentUrl;
    }, [location]);

    return null;
};

// Track custom events (NAME ONLY)
export const trackEvent = (eventName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({event: eventName});
};
