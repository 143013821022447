import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";

// Material UI components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

// Material Dashboard components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";

function DeleteAccount() {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmationStep, setConfirmationStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleLogout = async () => {
        try {
            // Send logout request to server (same as in Logout.js)
            await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
                method: 'POST',
                credentials: 'include', // Include HTTP-only cookies
            });

            // Clear any local storage items
            localStorage.clear();

            // Redirect to main page
            navigate('/');
        } catch (err) {
            console.error("Logout error:", err);
        }
    };

    const handleDeleteAccount = async () => {
        setIsSubmitting(true);
        setError(null);
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/deleteAccount`,
                {},
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );

            if (response.status === 200) {
                // Close the modal
                setModalVisible(false);

                // Perform logout and redirect
                await handleLogout();
            }
        } catch (error) {
            setError(error.response?.data?.message || "Failed to delete account");
        } finally {
            setIsSubmitting(false);
            setConfirmationStep(1);
        }
    };

    const handleAction = () => {
        if (confirmationStep === 1) {
            setConfirmationStep(2);
            return;
        }

        handleDeleteAccount();
    };

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Card>
            <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium" mb={3}>
                    Account Preferences
                </MDTypography>

                <Divider/>

                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={2}
                >
                    <MDBox display="flex" alignItems="center">
                        <MDTypography variant="body2" color="text">
                            Account Status:
                            <MDTypography
                                variant="body2"
                                fontWeight="medium"
                                color="dark"
                                display="inline"
                                ml={1}
                            >
                                Active
                            </MDTypography>
                        </MDTypography>

                        <Tooltip
                            title="Manage your account status here. Deleting your account will permanently remove all your data and cannot be reversed. You will no longer have access to the app."
                            placement="top"
                        >
                            <InfoIcon
                                fontSize="small"
                                color="info"
                                sx={{ml: 1, cursor: "pointer"}}
                            />
                        </Tooltip>
                    </MDBox>

                    <MDButton
                        variant="text"
                        color="info"
                        onClick={() => setModalVisible(true)}
                    >
                        Edit
                    </MDButton>
                </MDBox>

                <Divider/>
            </MDBox>

            {/* Confirmation Modal */}
            <Modal
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                    setConfirmationStep(1);
                    setError(null);
                }}
                aria-labelledby="delete-account-modal"
                aria-describedby="delete-account-confirmation"
            >
                <Box sx={modalStyle}>
                    <MDTypography variant="h6" component="h2" gutterBottom>
                        {confirmationStep === 1
                            ? "Delete Account"
                            : "Final Confirmation"
                        }
                    </MDTypography>

                    <MDBox mt={2} mb={3}>
                        {confirmationStep === 1 ? (
                            <MDTypography variant="body2" color="error" fontWeight="bold">
                                This will permanently delete your account and all associated data.
                                This action cannot be reversed and is permanent.
                            </MDTypography>
                        ) : (
                            <>
                                <MDTypography variant="body2" color="error" fontWeight="bold" mb={2}>
                                    Are you absolutely sure you want to delete your account?
                                </MDTypography>
                                <MDTypography variant="body2" color="error" fontWeight="bold">
                                    This will permanently delete your account and all associated data.
                                    This action cannot be reversed and is permanent.
                                </MDTypography>
                            </>
                        )}

                        {error && (
                            <MDBox mt={2}>
                                <MDTypography variant="body2" color="error">
                                    {error}
                                </MDTypography>
                            </MDBox>
                        )}
                    </MDBox>

                    <Divider/>

                    <MDBox mt={3} display="flex" justifyContent="space-between">
                        <MDButton
                            variant="outlined"
                            color="dark"
                            onClick={() => {
                                setModalVisible(false);
                                setConfirmationStep(1);
                                setError(null);
                            }}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </MDButton>

                        <MDButton
                            variant="gradient"
                            color="error"
                            onClick={handleAction}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <CircularProgress size={24} color="inherit"/>
                            ) : (
                                confirmationStep === 1 ? "Delete Account" : "Confirm Deletion"
                            )}
                        </MDButton>
                    </MDBox>
                </Box>
            </Modal>
        </Card>
    );
}

export default DeleteAccount;
