import React, {useEffect, useState} from 'react';
import {Form, Link, redirect, useActionData} from "react-router-dom";
import axios from "axios";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import Checkbox from "@mui/material/Checkbox";
import {trackEvent} from "../../../util/tracker";

function Signup() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const actionData = useActionData();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [termsError, setTermsError] = useState("");

    useEffect(() => {
        if (actionData && typeof actionData === 'object' && actionData.error) {
            console.log('Resetting submit state due to error'); // Debugging output
            setIsSubmitting(false); // Reset submitting state on error
        }
    }, [actionData]);
    // Handle password change
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    // Handle confirm password change
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    // Validate passwords on blur
    const validatePasswords = () => {
        if (password && confirmPassword && password !== confirmPassword) {
            setPasswordError("Passwords do not match");
        } else {
            setPasswordError("");
        }
    };

    // Handle phone number change
    const handlePhoneChange = (e) => {
        let value = e.target.value;

        // Check if the number is in the 10-digit format starting with 0
        if (value.length === 10 && value.startsWith('0')) {
            value = `+27${value.substring(1)}`; // Convert to international format
        }

        setPhone(value);
    };

    // Validate phone number
    const validatePhoneNumber = (value) => {
        if (!value) {
            setPhoneError("Please enter a phone number.");
            return;
        }

        // Hard length check first
        if (value.length !== 12) {
            setPhoneError("Phone number must be exactly 12 characters and no spaces (e.g. +27821234567)");
            return;
        }

        // Strict regex for South African numbers only
        const strictSAPattern = /^\+27[1-9]\d{8}$/;

        if (!strictSAPattern.test(value)) {
            setPhoneError("Enter a valid South African number (+27 followed by 9 digits)");
            return;
        }

        setPhoneError("");
    };


    // Handle email change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Validate email on blur
    const validateEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
        if (email && !emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        if (!acceptTerms) {
            e.preventDefault();
            setTermsError("You must accept the terms and conditions to proceed.");
        } else if (passwordError || phoneError || emailError) {
            e.preventDefault();
        } else {
            setIsSubmitting(true);
        }
    };


    const handleTermsChange = (e) => {
        setAcceptTerms(e.target.checked);
        if (e.target.checked) {
            setTermsError("");
        }
    };

    return (


        <MDBox pt={2} pb={3} px={5} mx={5}>
            <Form method="post" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                    <MDInput name="firstname" type="text" label="First Name" fullWidth required
                             autoComplete="given-name"/>
                </MDBox>
                <MDBox mb={2}>
                    <MDInput name="lastname" type="text" label="Last Name" fullWidth required
                             autoComplete="family-name"/>
                </MDBox>
                <MDBox mb={2}>
                    <MDInput
                        name="phone"
                        type="text"
                        label="Phone Number"
                        fullWidth
                        value={phone}
                        onChange={handlePhoneChange}
                        onBlur={() => validatePhoneNumber(phone)}
                        autoComplete="tel"// Validate on blur
                    />
                    {phoneError && (
                        <MDTypography variant="body2" color="error">
                            {phoneError}
                        </MDTypography>
                    )}
                </MDBox>
                <MDBox mb={2}>
                    <MDInput
                        name="email"
                        type="email"
                        label="Email"
                        fullWidth
                        required
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={validateEmail}
                        autoComplete="email"
                    />
                    {emailError && (
                        <MDTypography variant="body2" color="error">
                            {emailError}
                        </MDTypography>
                    )}
                </MDBox>
                <MDBox mb={2}>
                    <MDInput
                        name="password"
                        type="password"
                        label="Password"
                        fullWidth
                        required
                        value={password}
                        onChange={handlePasswordChange}
                        onBlur={validatePasswords}
                        autoComplete="new-password"
                    />
                </MDBox>
                <MDBox mb={2}>
                    <MDInput
                        name="password2"
                        type="password"
                        label="Re-Type Password"
                        fullWidth
                        required
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        onBlur={validatePasswords}
                        autoComplete="new-password"
                    />
                    {passwordError && (
                        <MDTypography variant="body2" color="error">
                            {passwordError}
                        </MDTypography>
                    )}
                </MDBox>
                {actionData && typeof actionData === 'object' && actionData.error && (
                    <MDBox mb={2}>
                        <MDTypography variant="body2" color="error" align="center">
                            {actionData.error} {/* Correctly access the error property */}
                        </MDTypography>
                    </MDBox>
                )}
                <MDBox mb={2} display="flex" alignItems="center">
                    <Checkbox
                        checked={acceptTerms}
                        onChange={handleTermsChange}
                        sx={{mr: 1}}
                    />
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{cursor: "pointer", userSelect: "none", ml: -1}}
                    >
                        I hereby accept the
                        <MDTypography
                            component={Link}
                            to="/termsAndConditions"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                        >
                            &nbsp;terms and conditions&nbsp;
                        </MDTypography>
                        and
                        <MDTypography
                            component={Link}
                            to="/privacyPolicy"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                        >
                            &nbsp;privacy policy
                        </MDTypography>
                        &nbsp;by submitting this form.
                    </MDTypography>
                </MDBox>
                {termsError && (
                    <MDTypography variant="caption" color="error">
                        {termsError}
                    </MDTypography>
                )}
                <MDBox mt={4} mb={1}>
                    <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                        disabled={!!passwordError || !!phoneError || !!emailError} // Disable button if there are any validation errors
                    >
                        {isSubmitting ? 'Signing up...' : 'Signup'}
                    </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                        Already Have an account?{" "}
                        <MDTypography
                            component={Link}
                            to="/login"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                        >
                            Login
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </Form>
        </MDBox>
    );
}

export default Signup;

// Action function for form submission
export async function signupAction({request}) {
    const formData = await request.formData();
    const postData = Object.fromEntries(formData);

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/signup`,
            {
                firstname: postData.firstname,
                lastname: postData.lastname,
                phone: postData.phone,
                email: postData.email,
                password: postData.password,
            },
            {withCredentials: true}
        );
        if (response.status === 201) {
            trackEvent('contact')
            return redirect(response.data.redirectUrl);
        }
    } catch (error) {
        if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data;
            if (errorMessage === "403 Error: User exists already, please login instead.") {
                return {error: "User already exists. Please login instead by clicking login below."};
            } else if (errorMessage === "403 Error: Insecure password, please try again.") {
                return {error: "Your password is not secure. It must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special symbol. Please try again with a stronger password."};
            }
        }

        // Return a generic error message for other cases
        return {error: "An error occurred. Please try again later."};
    }
}